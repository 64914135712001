import { Container, Row, Col, Button, Modal, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStairs, faBuilding, faCalendarDays, faLayerGroup, faDoorOpen, faDollarSign, faMoneyBillTrendUp, faSquareParking, faWarehouse, faTrowelBricks, faMapPin, faVectorSquare, faMap, faArchway, faRoad, faPlugCircleBolt } from '@fortawesome/free-solid-svg-icons';
import styles from './Offer.module.scss';
import Gallery from "../../features/Gallery/Gallery";
import { fetchOfferById, currentOffer } from "../../../redux/currentOfferReducer";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { deleteOffer, offersList } from "../../../redux/offersReducer";
import { status, clear } from "../../../redux/statusReducer";

const Offer = () => {
  const { id } = useParams('id');
  const dispatch = useDispatch();
  //const offer = useSelector(currentOffer);
  const offers = useSelector(offersList);

  const offer = offers.filter(off => off.id === id);
  const deletingStatus = useSelector(status);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //console.log(offer[0])

  /*useEffect(() => {
    dispatch(fetchOfferById(id));
  }, [dispatch]);

  const handleDelete = () => {
    dispatch(deleteOffer(id));
  }
  if(deletingStatus === 'success') return navigate('/buy');
  if(offer === null) return <h2>Loading</h2>
  if(offer !== null)*/

  return (
    <Container>
      <Row className="mx-1">
        {/*<h3>{ offer.name }<Button className="mx-5" variant="danger" onClick={() => handleShow()}>Usuń ofertę</Button></h3>*/}
        <Gallery offer={offer[0]} images={offer[0].photos} />
        <Col lg={{ order: 2, span: 6 }} md={{ order: 3 }} className={"my-2"}>
          {
            offer[0].building !== 'Działka'
            ?
            <Row className={styles["details-container"]}>
              <Col className={styles["detail-grey"]} lg={6} md={12}><div className={styles["icon"]}><FontAwesomeIcon icon={faDollarSign} /></div><div className={styles["detail"]}>Cena: <span>{ offer[0].price } zł</span></div></Col>
              <Col className={styles["detail-grey"]} lg={6} md={12}><div className={styles["icon"]}><FontAwesomeIcon icon={faDollarSign} /></div><div className={styles["detail"]}>Cena za m2: <span>{ offer[0].pricem2 } zł</span></div></Col>
              <Col className={styles["detail-lightgrey"]} lg={6} md={12}><div className={styles["icon"]}><FontAwesomeIcon icon={faStairs} /></div><div className={styles["detail"]}>Piętro: <span>{ offer[0].floor }</span></div></Col>
              <Col className={styles["detail-lightgrey"]} lg={6} md={12}><div className={styles["icon"]}><FontAwesomeIcon icon={faDoorOpen} /></div><div className={styles["detail"]}>Liczba pokoi: <span>{ offer[0].rooms }</span></div></Col>
              <Col className={styles["detail-grey"]} lg={6} md={12}><div className={styles["icon"]}><FontAwesomeIcon icon={faLayerGroup} /></div><div className={styles["detail"]}>Powierzchnia: <span>{ offer[0].surface } m2</span></div></Col>
              <Col className={styles["detail-grey"]} lg={6} md={12}><div className={styles["icon"]}><FontAwesomeIcon icon={faBuilding} /></div><div className={styles["detail"]}>Zabudowa: <span>{ offer[0].building }</span></div></Col>
              <Col className={styles["detail-lightgrey"]} lg={6} md={12}><div className={styles["icon"]}><FontAwesomeIcon icon={faMoneyBillTrendUp} /></div><div className={styles["detail"]}>Rynek: <span>{ offer[0].market }</span></div></Col>
              <Col className={styles["detail-lightgrey"]} lg={6} md={12}><div className={styles["icon"]}><FontAwesomeIcon icon={faCalendarDays} /></div><div className={styles["detail"]}>Rok budowy: <span>{ offer[0].year }</span></div></Col>
              <Col className={styles["detail-grey"]} lg={6} md={12}><div className={styles["icon"]}><FontAwesomeIcon icon={faSquareParking} /></div><div className={styles["detail"]}>Piwnica: <span>{ offer[0].basement ? 'Tak' : 'Nie' }</span></div></Col>
              <Col className={styles["detail-grey"]} lg={6} md={12}><div className={styles["icon"]}><FontAwesomeIcon icon={faWarehouse} /></div><div className={styles["detail"]}>Garaż: <span>{ offer[0].garage ? 'Tak' : 'Nie' }</span></div></Col>
            </Row>
            :
            <Row className={styles["details-container"]}>
              <Col className={styles["detail-grey"]} lg={6} md={12}><div className={styles["icon"]}><FontAwesomeIcon icon={faDollarSign} /></div><div className={styles["detail"]}>Cena: <span>{ offer[0].price } zł</span></div></Col>
              <Col className={styles["detail-lightgrey"]} lg={6} md={12}><div className={styles["icon"]}><FontAwesomeIcon icon={faDollarSign} /></div><div className={styles["detail"]}>Cena za m2: <span>{ offer[0].pricem2 } zł</span></div></Col>
              <Col className={styles["detail-lightgrey"]} lg={6} md={12}><div className={styles["icon"]}><FontAwesomeIcon icon={faLayerGroup} /></div><div className={styles["detail"]}>Powierzchnia: <span>{ offer[0].surface } m2</span></div></Col>
              <Col className={styles["detail-grey"]} lg={6} md={12}><div className={styles["icon"]}><FontAwesomeIcon icon={faTrowelBricks} /></div><div className={styles["detail"]}>Typ działki: <span>{ offer[0].purpose }</span></div></Col>
              <Col className={styles["detail-grey"]} lg={6} md={12}><div className={styles["icon"]}><FontAwesomeIcon icon={faVectorSquare} /></div><div className={styles["detail"]}>Kształt: <span>{ offer[0].shape }</span></div></Col>
              <Col className={styles["detail-lightgrey"]} lg={6} md={12}><div className={styles["icon"]}><FontAwesomeIcon icon={faArchway} /></div><div className={styles["detail"]}>Ogrodzenie: <span>{ offer[0].fence }</span></div></Col>
              <Col className={styles["detail-lightgrey"]} lg={6} md={12}><div className={styles["icon"]}><FontAwesomeIcon icon={faMap} /></div><div className={styles["detail"]}>Położenie: <span>{ offer[0].locus }</span></div></Col>
              <Col className={styles["detail-grey"]} lg={6} md={12}><div className={styles["icon"]}><FontAwesomeIcon icon={faMapPin} /></div><div className={styles["detail"]}>Lokalizacja: <span>{ offer[0].location }</span></div></Col>
              <Col className={styles["detail-grey"]} lg={6} md={12}><div className={styles["icon"]}><FontAwesomeIcon icon={faRoad} /></div><div className={styles["detail"]}>Dojazd: <span>{ offer[0].driveway }</span></div></Col>
              <Col className={styles["detail-lightgrey"]} lg={6} md={12}><div className={styles["icon"]}><FontAwesomeIcon icon={faPlugCircleBolt} /></div><div className={styles["detail"]}>Media: <span>{ offer[0].media }</span></div></Col>
            </Row>
          }
        </Col>
        <Col md={{ order: 4 }} xs={12}>
          <h3>Opis nieruchomości</h3>
          <div className={styles['description']} dangerouslySetInnerHTML={{__html: offer[0].description}}></div>
        </Col>
      </Row>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Usuwanie oferty</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Czy napewno chcesz usunąć ofertę? Zmiany będą nie - od - wra - cal - ne.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Nie
          </Button>
          {/*<Button variant="danger" onClick={() => handleDelete()}>{deletingStatus === 'proccessing' ? <Spinner size="sm"/> : 'Tak'}</Button>*/}
        </Modal.Footer>
      </Modal>

    </Container>
  )
}

export default Offer;